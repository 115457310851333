<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 80px;">
                                产品名称:
                            </td>
                            <td>
                                <el-input v-model="searchItem.materialName" size="small"
                                          placeholder="请输入产品名称"></el-input>
                            </td>
                            <td style="width: 80px">
                                适用机型:
                            </td>
                            <td>
                                <el-select v-model="searchItem.useType" size="small" style="width: 100%"
                                           placeholder="请选择适用机型">
                                    <el-option
                                            v-for="item in useTypeList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                            <td style="width: 80px">
                                使用性质:
                            </td>
                            <td>
                                <el-select v-model="searchItem.useNature" size="small" style="width: 100%"
                                           placeholder="请选择使用性质">
                                    <el-option
                                            v-for="item in useNatureList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                        </tr>
                        <tr>
                            <td style="width: 80px;padding-top: 10px">部件:</td>
                            <td>
                                <el-select v-model="searchItem.component" size="small"
                                           style="padding-top: 10px;width: 100%"
                                           placeholder="请选择部件">
                                    <el-option
                                            v-for="item in componentList"
                                            :key="item.wholeCode"
                                            :label="item.name"
                                            :value="item.wholeCode">
                                    </el-option>
                                </el-select>
                            </td>
                            <td></td>
                            <td></td>
                            <td style="width: 300px ;text-align: center;padding-top: 10px" colspan="2">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" style="margin-left: 20%"
                                           @click="reset">重置
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="64" label="排序" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialCode" width="200" label="物料编码"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useTypeName" width="200" label="适用机型"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="componentName" width="200" label="部件"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="useNatureName" width="200" label="使用性质"
                                             align="center" show-overflow-tooltip></el-table-column>
                            <el-table-column prop="materialName" label="产品名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="model" width="200" label="型号" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="quantity" width="150" label="库房库存" align="center"
                                             show-overflow-tooltip></el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                pageList: [15, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                option: {
                    pageSize: 15,
                    pageIndex: 11,
                },
                totalPage: 10,
                searchItem: {
                    materialName: '',
                    useType: '',
                    useNature: '',
                    component: '',
                    pageIndex: 1,
                    pageSize: 15,
                },
                lineHeight: '',
                componentList: [],
                useTypeList: [],
                useNatureList: [],
            };
        },
        methods: {
            getDictionary() {
                this.$api.getDictionaryData().then(res => {
                    if (res.code == 200) {
                        this.useTypeList = res.data.useTypeList;
                        this.componentList = res.data.componentList;
                        this.useNatureList = res.data.useNatureList;
                    }
                })
            },
            pageChange(option) {
                this.option.pageIndex = option;
                this.searchItem.pageIndex = option;
                this.$api.getAfterSale(this.searchItem).then(res => {
                    if (res.code == 200) {
                        this.tableData = res.data.content
                        this.totalPage = res.data.totalElements
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
            },
            reset() {
                this.searchItem = {
                    materialName: '',
                    useType: '',
                    useNature: '',
                    component: '',
                    pageIndex: 1,
                    pageSize: 15,
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
        },
        created() {
            this.getDictionary();
            this.tableHeight = (window.innerHeight) * 0.71
            this.pageChange(1);
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 100px;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 10px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    td {
        text-align: right;
    }
</style>